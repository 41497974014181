$(() => {
  $('.popup-link').each(function(_, popup) {
    $(popup).magnificPopup({
      type:'inline',
      preloader: false,
      closeBtnInside: true,
    })
  })

  $('.popup-close').on("click", function() {
    $.magnificPopup.close()
  })
})
