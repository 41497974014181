function getSlug(text) {
  // Replicate Rails parameterize
  return text.toLowerCase().replace(/[^a-z0-9]+/g,'-').replace(/(^-|-$)/g,'')
}

function disableInheritedSelectedRoles() {
  $("select.roles").each(function(_, select) {
    // Ensure that roles inherited from the parent can't be unselected
    $(select).find('option').each(function(_, option) {
      if ($(select).data('fixed') && $(select).data('fixed').includes(parseInt($(option).val()))) {
        $(select).parent().find(`.select2-selection__choice[title="${$(option).text()}"]`).addClass('fixed')
      }
    })
  })
}

function initializeSelect2Fields() {
  $("select.searchable").select2()
  $("select:not(.searchable):not(.roles)").select2({ minimumResultsForSearch: -1 })
  $("select.roles").select2({ placeholder: 'All roles' })
}

$(() => {
  initializeSelect2Fields()
  disableInheritedSelectedRoles()

  $(document).on("click", "a.delete_entity", function(e) {
    if (!confirm("Are you sure?")) {
      e.preventDefault()
      e.stopImmediatePropagation()
    }
  })

  $('.roles').on('select2:unselecting', function(event) {
    // Ensure that roles inherited from the parent can't be unselected
    if ($(event.target).data('fixed').includes(parseInt(event.params.args.data.id))) {
      event.preventDefault()
    }
  })

  $("form.new_comment, form.edit_comment").on("submit", function(event) {
    $(event.target).find("button[type='submit']").addClass('loading')
  })

  $('.roles').on('select2:select select2:unselect', disableInheritedSelectedRoles)

  $(".custom-form form").on("submit", function(event) {
    $(event.target).find("button[type='submit']").addClass('loading')

    var container = $(event.target).closest('.custom-form')
    container.find('.result-container .alert').addClass('hidden')
  })

  $(".custom-form form").on("ajax:success", function(event) {
    var container = $(event.target).closest('.custom-form')

    if (event.detail[0] && event.detail[0].message) {
      $(event.target).find("button[type='submit']").removeClass('loading')
      container.find('.form-container').hide()
      container.find('.result-container .notice').removeClass('hidden').append(event.detail[0].message)

      if (container.find('.result-container .notice')[0]) {
        container.find('.result-container .notice')[0].scrollIntoView(false)
      }
    }
  })

  $(".custom-form form").on("ajax:error", function(event) {
    $(event.target).find("button[type='submit']").removeClass('loading')

    var container = $(event.target).closest('.custom-form')
    container.find('.result-container .alert').removeClass('hidden')
  })

  if ($(".page_form").length) {
    $("#page_subject").on("input", function(event) {
      $("#page_slug").val(getSlug(event.target.value))
    })

    $("#page_form_action_type").on("select2:select", function(event) {
      if (event.target.value == 'send_email') {
        $('.field.action-target').removeClass('hidden')
      } else {
        $('.field.action-target').addClass('hidden')
      }
    })

    $(".add-form").on("click", function() {
      $('#page_form_active').val(true)
      $('.add-form-fields').removeClass('hidden')
      $('button.add-form').addClass('hidden')
    })

    $(".remove-form").on("click", function() {
      $('#page_form_active').val(false)
      $('.add-form-fields').addClass('hidden')
      $('button.add-form').removeClass('hidden')
    })

    $(document).on('nested:fieldAdded', function(event) {
      event.field.find("select").select2({
        minimumResultsForSearch: -1,
      })

      var maxOrder = 0
      event.field.parent().closest('.fields').find('.order-in-section').each(function() {
        var value = parseInt($(this).find('input').val())
        if (value > maxOrder) {
          maxOrder = value
        }
      })

      event.field.find(".order-in-section input").val(maxOrder + 1)
    })

    $(document).on("select2:select", ".select-question-type", function(event) {
      if (['buttons', 'dropdown'].includes(event.target.value)) {
        $(event.target).closest('.fields').find('.add-option-fields').removeClass('hidden')
      } else {
        $(event.target).closest('.fields').find('.add-option-fields').addClass('hidden')
      }
    })

    $(document).on("trix-file-accept", function(event) {
      const acceptedTypes = ['image/jpeg', 'image/png']
      if (!acceptedTypes.includes(event.originalEvent.file.type)) {
        event.preventDefault()
        alert("Only JPG / PNG images are allowed")
      }
    })
  }
})
