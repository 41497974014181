$(() => {
  $("aside nav").jstree(
    {
      "core":
      {
        "themes": {
          "theme": "default",
          "dots": false,
          "icons": false
        },
      },
      "state":
      {
        "key": "navigation"
      },
      "plugins" : ["html_data", "state", "themes", "wholerow"]
    },
  ).bind("ready.jstree", function(_e, _data) {
    $(".jstree-node.selected").removeClass("selected")
    $(".jstree-clicked").removeClass("jstree-clicked")

    var href = $("aside nav").attr("data-selected")
    var link = $("aside nav a[href='" + href + "']")
    link.addClass("jstree-clicked")
    link.parents(".jstree-node:last").addClass("selected")
  })

  $("aside nav").on("click", ".jstree-anchor", function(event) {
    var url = $(event.target).attr("href")
    window.location.href = url
  })
})
