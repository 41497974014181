// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You"re encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"

// Modules
require("jstree")
require("select2")
require("trix")
require("magnific-popup")
require("@rails/actiontext")

import "datatables.net"

Rails.start()
ActiveStorage.start()

import "../vendor/nested_form"

import "../components/forms"
import "../components/navigation"
import "../components/tables"
import "../components/popups"
